//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//const { Conflux, Drip } = require("js-conflux-sdk");
import { getAddress, conflux, Drip } from "../../utils/cfx";
import "viewerjs/dist/viewer.css";
import { component as Viewer } from "v-viewer";
export default {
  name: "My",
  data() {
    return {
      active: "0",
      nft_url: "http://127.0.0.1:7001/public/_nft/",
      username: "",
      myhead: "",
      id: 0,
      tid: 0, // 售卖 tokenid
      conflux: null,
      coinname: "",
      userinfo: { name: "" },
      name: "",
      balance: "",
      islogin: false,
      activeNames: ["1"],
      activeNames2: ["1"],
      earned: "",
      profit: "",
      art: "",
      activeNames3: ["1"],
      activeNames4: ["1"],
      activeNames5: ["1"],
      activeNames6: ["1"],
      activeNames7: ["1"],
      activeNames8: ["1"],
      activeNames9: ["1"],
      activeNames10: ["1"],
      activeNames11: ["1"],
      goods: [],
      goodssale: [], // 售卖中
      goodsauction: [], // 我向外拍卖的NFT
      goodswinning: [], // 我向内竞价成功的NFT
      currdesc: "",
      amount: "",
      amount2: "",
      price: "",
      price2: "", //拍卖起价
      price3: "", //拍卖加价幅度
      endtime: "", // 拍卖结束时间
      zhuzaozhe: "",
      no: "",
      staking: "",
      currtitle: "",
      currbizhi: "",
      thumb: "",
      releaseitem: {},

      change: "",
      exchangeval: "",

      directStake: "",

      zhuzaotitle: "",
      zhuzaoprice: "",
      show: false,
      show2: false,
      showaccount: false,
      showcancel: false,
      zhuzaoshow: false,

      castheadshow: false,
      title: "",
      hash: "",
      desc: "",
      signanme: "",
      cfxtype: "0",

      images: [""],

      /* ------------ 老合约部分 --------- */
      oldTokenIds: "", //合约中老NFT ids
      oldnft: null, // 我的NFT
      oldsellnft: null, // 我的售卖中的NFT

      oldGoodsOff: false, // 旧合约 "商品下架" 窗口开关
    };
  },
  components: {
    Viewer,
  },
  activated() {
    this.init();
  },
  mounted() {
    this.nft_url = this.$store.getters.nft_url;
    window.setMybalance = this.setMybalance;
    window.setMyearned = this.setMyearned;
    window.setMyprofit = this.setMyprofit;
    window.setMyart = this.setMyart;
    window.setMycoinname = this.setMycoinname;
    window.setStakingbalance = this.setStakingbalance;
    this.screenWidth = 600; //document.body.clientWidth; // 屏幕宽
    this.init();
  },
  methods: {
    async getAddress() {
      this.conflux = new this.$conflux({
        url: window.RPC_URL,
        networkId: 1029,
        logger: console,
      });
      this.conflux.provider = window.conflux;

      let accounts = null;
      let _address = "";
      try {
        accounts = await conflux.request({ method: "cfx_requestAccounts" });
        _address = accounts + "";
        window._address = _address;
      } catch (error) {
        console.log("error");
      }
      if (_address === "") {
        try {
          accounts = await window.conflux.enable();
        } catch (error) {}
        // 新版本手机
        if (window.isArrayFn(accounts)) {
          _address = accounts[0];
        } else {
          const accounts2 = await window.conflux.send({ method: "cfx_accounts" });
          _address = accounts2.result[0];
        }
        window._address = _address;
      }
      localStorage.setItem("_address", _address);

      try {
        document.getElementById("accname").innerHTML =
          '<div class="qb_btn">' +
          _address.substring(0, 6) +
          "..." +
          _address.substring(45, _address.lenght) +
          "</div>";
      } catch (error) {}

      return _address;
    },

    async init() {
      if (document.body.clientWidth >= 768) {
        this.$router.replace({ path: "/my" });
      }

      // 是否登录 显示address 简写
      /*if (this.$store.state.address) {
        this.islogin = true;
        const my_address = this.$store.state.address;
        this.name = my_address.substring(0, 6) + "..." + my_address.substring(36, 42);
      }

      try {
        const accounts = await conflux.request({ method: "cfx_requestAccounts" });
        window._address = accounts + "";
        localStorage.setItem("_address", window._address);
      } catch (error) {
        console.log(window.conflux);
      }*/
      const _address = await this.getAddress();

      this.$axios.defaults.headers.common["A-ADDRESS"] = _address;
      this.$axios.defaults.headers.common.Authorization = _address; // AJAX 配置 Authorization

      this.$axios.get(this.$store.state.api + "/users/0").then((response) => {
        //console.log(response);
        this.userinfo = response.data;
        this.myhead =
          this.$store.getters.nft_url_t.replace("temp_pic_pro", "temp_pic_v1") +
          "111/head/" +
          this.userinfo.pic;
        //console.log(this.myhead);
      });

      // 新合约
      let tokenids = "";
      try {
        this.conflux = new this.$conflux({
          url: window.RPC_URL,
          networkId: 1029,
          logger: console,
        });
        this.conflux.provider = conflux;

        const ArtNFT_V2 = await this.conflux.Contract({
          abi: ArtNft_V2_ABI,
          address: ArtNft_V2_ADDRESS,
        });
        const sta = await ArtNFT_V2.totalBalance();
        const staking = Drip(sta).toCFX();
        this.staking = staking;

        const listArtNFT_V2 = await ArtNFT_V2.tokensOf(_address); // 新合约 我的nft
        //console.log(listArtNFT_V2);

        listArtNFT_V2.forEach(async (element) => {
          tokenids += element + ",";
          const listuri = await ArtNFT_V2.uri(+element);
          const meta = listuri.replace("http://nft.", "").replace("nft.", "");
          //console.log("新合约: " + element + " -> " + meta);
          //const amount = await ArtNFT_V2.balanceOf(_address, +element);
          //console.log("" + amount);
        });
        // 我的铸造
        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              ids: tokenids,
              addr: ArtNft_V2_ADDRESS,
            },
          })
          .then((response) => {
            let goods = [];
            response.data.rows.forEach(async (element) => {
              const amount = await ArtNFT_V2.balanceOf(_address, element.tokenid); // 我剩余的 块
              element.stock = +amount;
              goods.push(element);
            });
            listArtNFT_V2.forEach(async (element) => {
              response.data.rows.forEach(async (element2) => {
                if ((element + "") != (element2.tokenid + "")) {
                  //goods.push({ "id": -1, "tokenid": +element });
                }
              });
            });
            this.goods = goods;
          });

        // 我的售卖
        const SHOP_V2 = await this.conflux.Contract({
          abi: ConfluxShop_V2_ABI,
          address: ConfluxShop_V2,
        });
        //console.log(SHOP_V2);
        const shopids = await SHOP_V2.getSellerShopItems(_address);
        //console.log(shopids + "我的售卖");

        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              ids: shopids + "",
              addr: ArtNft_V2_ADDRESS,
            },
          })
          .then((response) => {
            let goods = [];
            response.data.rows.forEach(async (element) => {
              const shopitem = await SHOP_V2.shopMapNew(
                ArtNft_V2_ADDRESS,
                element.tokenid
              ); // 售卖中的信息
              const amount = shopitem[3];
              element.stock = +amount;
              goods.push(element);
            });
            this.goodssale = goods;
          });
        //console.log(ArtNFT_V2);

        /*
        for (let index = 0; index < shopids.length; index++) {
          const element = shopids[index];
          const ssss = await ArtNFT_V2.balanceOf(_address, +element); // 铸造中的 amount
          console.log("ArtNFT_V2 " + element + " amount: " + ssss);

          const tt = await SHOP_V2.sellerShops_amount(_address, index); // 售卖中的 amount
          console.log(element + " amount: " + tt);
        }*/

        // 我的拍卖
        const SHOPPAI_V2 = await this.conflux.Contract({
          abi: ConfluxShop_PaiV2_ABI,
          address: ConfluxShop_PaiV2,
        });
        const paishopids = await SHOPPAI_V2.getSellerShopItems(_address);
        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              ids: paishopids + "",
              addr: ArtNft_V2_ADDRESS,
            },
          })
          .then((response) => {
            let goods = [];
            response.data.rows.forEach(async (element) => {
              const paiitem = await SHOPPAI_V2.shopMapNew(
                ArtNft_V2_ADDRESS,
                element.tokenid
              );

              const amount = paiitem[6];
              element.stock = +amount;
              element.bidnum = +paiitem[4];
              goods.push(element);
            });
            this.goodsauction = goods;
          });

        // 竞拍中的NFT
        this.$axios
          .get(this.$store.state.api + "/digital", {
            params: {
              aid: _address,
            },
          })
          .then((response) => {
            let goods = [];
            response.data.rows.forEach(async (element) => {
              const paiitem = await SHOPPAI_V2.shopMapNew(
                ArtNft_V2_ADDRESS,
                element.tokenid
              );
              const amount = paiitem[6];
              element.stock = +amount;
              element.bidnum = +paiitem[4];
              goods.push(element);
            });
            this.goodswinning = goods;
          });
      } catch (error) {
        console.log(error);
      }

      const balance = await conflux.cfx.getBalance(_address);
      const tbalance = Drip(balance).toCFX(); // 获取 CFX
      const mybalance = parseFloat(tbalance).toFixed(4);
      this.balance = mybalance;

      // 矿池中 我的收益
      const MainArtPool = conflux.Contract({
        abi: MainArtPool_ABI,
        address: MainArtPool_ADDRESS,
      });
      const t2 = await MainArtPool.balanceOf(_address);
      const tttt = Drip(t2).toCFX(); // 获取 CFX
      const earnedt = parseFloat(tttt).toFixed(4);
      this.earned = earnedt;

      const profitT = await MainArtPool.earned(_address);
      const profitR = Drip(profitT).toCFX(); // 获取 CFX
      const profit = parseFloat(profitR).toFixed(4);
      this.profit = profit;

      const ArtCoin = conflux.Contract({
        abi: CONTRACT_ABI,
        address: CONTRACT_ADDRESS,
      });
      const coin = await ArtCoin.balanceOf(_address); // 用户tokenHolder的余额
      const name = await ArtCoin.name();
      this.name = name;
      //console.log(Drip(coin).toCFX());
      this.coinname = name;
      this.art = parseFloat(Drip(coin).toCFX()).toFixed(4);

      // 老合约--------------------------------------------------
      const ArtNFT = await conflux.Contract({
        abi: ArtNFT_ABI,
        address: ArtNFT_ADDRESS,
      });
      const sta = await ArtNFT.totalBalance();
      //const staking = Drip(sta).toCFX();
      //this.staking = staking;

      // 老合约 我的NFT
      const listArtNFT = await ArtNFT.tokensOf(_address);
      //this.oldnft = listArtNFT;
      //console.log(listArtNFT);

      //老合约  区块链的值
      let tokenidsOld = "";
      listArtNFT.forEach(async (element) => {
        tokenidsOld += element + ",";
        //const listuri = await ArtNFT.uri(+element);
        //const old_id = listuri.replace("http://nft.", "");
        //console.log("老合约" + element + " -> " + old_id);
      });
      this.oldTokenIds = tokenidsOld;

      let that = this;
      this.$axios.defaults.headers.common["A-ADDRESS"] = _address;
      this.$axios
        .get(this.$store.state.api + "/oldnft") // 获取我的老合约
        .then((response) => {
          const data = response.data;
          if (data) {
            // 每次获取后 用新合约抵消 listArtNFT_V2
            this.$axios
              .put(this.$store.state.api + "/oldnft", {
                txt: tokenidsOld,
                v2: tokenids,
              })
              .then((response) => {
                const txt = response.data.txt;
                that.getOldNft(txt);
              });
          } else {
            //第一次添加老合约
            this.$axios
              .post(this.$store.state.api + "/oldnft", {
                original: tokenidsOld,
                txt: tokenidsOld,
              })
              .then((response) => {
                const txt = response.data.txt;
                that.getOldNft(txt);
              });
          }
        });

      /*
      this.$axios.get(this.$store.state.api + "/my").then((response) => {
        this.userinfo = response.data;
        this.myhead = "http://www.artii.top/temp_pic/111/head/" + this.userinfo.pic;
      });

      // 我的拍卖中的作品(包含购买成功后)
      const SHOPPAI = await conflux.Contract({
        abi: PAI_ABI,
        address: PAI_ADDRESS,
      });
      const paishopids = await SHOPPAI.getSellerShop(_address); // 拍卖池中的作品
      //console.log("old pai" + paishopids);

      paishopids.forEach(async (element) => {
        const tokenid = element + "";
        const pai = await SHOPPAI.shopMap(+tokenid); // 266 280

        const price1 = pai[1] / 1e18;
        const price2 = pai[3] / 1e18;

        const timestamp = pai[5] + "";
        var d = new Date(timestamp * 1000);
        var date =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1) +
          "-" +
          d.getDate() +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes() +
          ":" +
          d.getSeconds();

        //console.log(pai);
      });*/
    },
    async getOldNft(tokenidsOld) {
      // 老合约 My NFT
      this.$axios
        .get(this.$store.state.api1 + "/v2/my/nft?id=" + tokenidsOld) // + 500
        .then((response) => {
          this.oldnft = response.data;
        });

      const _address = await this.getAddress();
      // 老合约 售卖中 NFT
      const SHOP = await conflux.Contract({
        abi: SHOP_ABI,
        address: SHOP_ADDRESS,
      });
      const shopids = await SHOP.getSellerShop(_address);
      this.oldsellnft = shopids;

      let tokenidsOldSell = "";
      shopids.forEach(async (element) => {
        tokenidsOldSell += element + ",";
      });
      this.$axios
        .get(this.$store.state.api1 + "/v2/my/nft?id=" + tokenidsOldSell) //  + 500
        .then((response) => {
          this.oldsellnft = response.data;
        });
    },
    showlingshouyi() {},
    setMybalance(val) {
      this.balance = val;
    },
    setMyearned(val) {
      this.earned = val;
    },
    setMyprofit(val) {
      this.profit = val;
    },
    setMyart(val) {
      this.art = val;
    },
    setMycoinname(val) {
      this.coinname = val;
    },
    setStakingbalance(val) {
      this.staking = val;
    },
    editAccount() {
      this.showaccount = true;
    },
    // 查询账户信息
    async queryCapitalInfo() {},
    // 关闭售卖
    closes() {
      this.zhuzaozhe = "";
      this.show = false;
    },
    closes2() {
      this.zhuzaozhe = "";
      this.showcancel = false;
    },
    // 头像铸造
    castNFT(uri) {
      console.log(uri);
      let timeuid = localStorage.getItem("_timeuid");
      console.log(timeuid);
      // 转移头像位置
      this.$axios
        .put(this.$store.state.api + "/cast/header", {
          picpath: timeuid,
          img: uri,
        })
        .then((response) => {
          const data = response.data;
          console.log(data);
          // 调用铸造
          this.hash = data;
          document.getElementById("znt3").src =
            "https://www.artii.top/nftpic/" + timeuid + "/" + uri;
          this.castheadshow = true;
        });
    },
    async makeitNew() {
      console.log("Cast");
      // post 到 paintings表
      let timeuid = localStorage.getItem("_timeuid");
      let _address = await window.getAddress();
      this.$axios
        .post(this.$store.state.api + "/paintings", {
          tokenid: 0,
          contract_address: ArtNft_V2_ADDRESS,
          tmp_uid: +timeuid, // 临时身份  picpath
          address: _address,
          hash: this.hash,
          img: this.userinfo.pic,
          price: this.price,
          name: this.title,
          signature: this.signanme,
          introduction: this.desc,
          is_cast: 0,
        })
        .then(async (response) => {
          const data = response.data;
          //console.log(data);
          if (data) {
            let _address = await window.getAddress();
            // 铸造合约
            const ArtNFT_V2 = await this.conflux.Contract({
              abi: ArtNft_V2_ABI,
              address: ArtNft_V2_ADDRESS,
            });

            // 是否授权
            const ACFX = this.conflux.Contract({
              abi: ACFX_ABI,
              address: ACFX_ADDRESS,
            });
            const is = await ACFX.isOperatorFor(ArtNft_V2_ADDRESS, _address);
            if (!is) {
              this.$toast.loading({
                message: "contract authorization in progress...",
                duration: 0,
                overlay: true,
                forbidClick: true,
              });
              await ACFX.authorizeOperator(ArtNft_V2_ADDRESS)
                .sendTransaction({
                  from: _address,
                })
                .executed();
              this.$toast.clear();
            }

            // 铸造
            try {
              let acfx = 0;
              let cfx = 0;
              if (this.cfxtype === "0") {
                cfx = +this.$drip.fromCFX(+data.price);
              } else {
                acfx = +this.$drip.fromCFX(+data.price);
              }
              this.$toast.loading({
                message: "processing...",
                duration: 0,
                overlay: true,
                forbidClick: true,
              });
              const _artNFT = await ArtNFT_V2.mint(data.hash, +acfx)
                .sendTransaction({
                  value: +cfx,
                  from: _address,
                })
                .executed();

              const tokens = await ArtNFT_V2.tokensOf(_address);
              const tokenid = tokens.pop(); // 新铸造的NFT
              // 放到 数字资产数据库
              this.$axios
                .put(this.$store.state.api + "/paintings/" + data.id, {
                  tokenid: tokenid,
                  is_cast: 1,
                })
                .then((response) => {
                  this.show2 = false;
                  this.$router.push({ path: "/my" });
                });
              this.init();
            } catch (error) {
              this.$toast.clear();
              this.castheadshow = false;
            }
          }
          this.$toast.clear();
          this.castheadshow = false;
        });
    },
    closesCastHeader() {
      this.castheadshow = false;
    },
    // 兑换
    async exchange() {
      if (!this.exchangeval) {
        alert("Please enter a number");
        return;
      }

      const _address = await window.getAddress();

      // 链接数据
      this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
        "_address"
      );

      let timeuid = "";
      if (!localStorage.getItem("_timeuid")) {
        timeuid = new Date().getTime();
        localStorage.setItem("_timeuid", timeuid);
      } else {
        timeuid = localStorage.getItem("_timeuid");
      }

      this.show2 = true;
      this.zhuzaoshow = false;

      const isapr = await PREPARE.allowance(_address, EXCHANGE_ADDRESS);

      if (+isapr < +this.exchangeval) {
        await PREPARE.approve(
          "0x885d5ddc4c16e553da0d5913e175a5436bb22c31",
          100000000000000000000000
        )
          .sendTransaction({
            from: _address,
          })
          .executed();
      }
      try {
        let collateral = await window.EXCHANGEPAI.exchange(
          this.exchangeval * 1e18
        ).estimateGasAndCollateral({ from: _address });
        //console.log(collateral);
        const _EXCHANGEPAI = await window.EXCHANGEPAI.exchange(this.exchangeval * 1e18)
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();

        //console.log(_EXCHANGEPAI);

        this.show2 = false;
        this.$router.push({ path: "/my" });
      } catch (error) {
        this.show2 = false;
        this.zhuzaoshow = false;
      }

      const converted = await window.EXCHANGEPAI.currentSupply();
      const change = this.cfxmy(converted);
      this.change = +change;
    },
    async getReward() {
      this.$toast.loading({
        message: "processing...! Do not refresh...",
        duration: 0,
        overlay: true,
        forbidClick: true,
      });
      try {
        const reward = await window.MainArtPool.getReward()
          .sendTransaction({
            from: _address,
          })
          .executed();
        this.$toast.clear();
        this.init();
      } catch (error) {
        this.$toast.clear();
      }
    },
    // 调起售卖 填写价格
    async release(item) {
      this.id = +item.id;
      this.tid = +item.tokenid;
      this.thumb = this.nft_url + item.picpath + "/" + item.img; //"http://www.artii.top/nftpic/" + item.picpath + "/" + item.img;
      this.currtitle = item.title;
      this.currbizhi = item.price;
      this.no = item.tokenid;
      this.releaseitem = item;
      this.currdesc = item.des;

      try {
        this.zhuzaozhe =
          item.address.substring(0, 6) +
          "..." +
          item.address.substring(45, item.address.lenght);
      } catch (error) {
        console.log(error);
      }
      this.show = true;

      const _address = await window.getAddress();
      const ArtNFT_V2 = await this.conflux.Contract({
        abi: ArtNft_V2_ABI,
        address: ArtNft_V2_ADDRESS,
      });

      //const price = await ArtNFT_V2.userMintPrices(+this.tid); // 铸造时候的值 永不变
      //const p1 = Drip(price).toCFX();

      const amount = await ArtNFT_V2.balanceOf(_address, +this.tid); // 我剩余的 块
      this.amount = +amount;
      this.amount2 = +amount;

      setTimeout(() => {
        this.active = "0";
      }, 100);
    },
    // 上架售卖 POST
    async releaseit() {
      if (!this.price) {
        alert("Please enter price");
        return;
      }

      const _address = await window.getAddress();

      const price = +this.price;

      //const _address = localStorage.getItem("_address");

      const ArtNFT_V2 = await this.conflux.Contract({
        abi: ArtNft_V2_ABI,
        address: ArtNft_V2_ADDRESS,
      });
      const is = await ArtNFT_V2.isApprovedForAll(_address, ConfluxShop_V2);
      if (!is) {
        this.$toast.loading({
          message: "contract authorization in progress...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const collateral1 = await ArtNFT_V2.setApprovalForAll(
          ConfluxShop_V2,
          true
        ).estimateGasAndCollateral({ from: _address });
        await ArtNFT_V2.setApprovalForAll(ConfluxShop_V2, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();
        this.$toast.clear();
      }

      this.show2 = true;
      this.show = false;

      try {
        // 挂出售卖
        const SHOP_V2 = await this.conflux.Contract({
          abi: ConfluxShop_V2_ABI,
          address: ConfluxShop_V2,
        });

        let collateral = await SHOP_V2.sellTicker(
          +this.tid, // tokenid
          +this.$drip.fromCFX(price), // price
          +this.amount, // amount
          ArtNft_V2_ADDRESS // NFT_address
        ).estimateGasAndCollateral({ from: _address });
        //console.log(collateral);

        const _shop = await SHOP_V2.sellTicker(
          +this.tid, // tokenid
          +this.$drip.fromCFX(price), // price
          +this.amount, // amount
          ArtNft_V2_ADDRESS // NFT_address
        )
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();

        //设置价格
        this.$axios
          .put(this.$store.state.api + "/digital/" + this.id, {
            price: +price,
            sell_stock: +this.amount,
            sell_contract_address: ConfluxShop_V2,
          })
          .then((response) => {
            this.init();
          });
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("Your NFT is already in Shop") > 0) {
          this.$dialog.alert({
            message: "This NFT is already in Shop",
          });
        }
        this.show2 = false;
      }
      this.show2 = false;
    },
    // 上架拍卖
    async auction() {
      if (!this.price2) {
        alert("Please enter the starting price");
        return;
      }

      if (!this.price3) {
        alert("Please enter the minimum markup");
        return;
      }

      const _address = await window.getAddress();

      const ArtNFT_V2 = await this.conflux.Contract({
        abi: ArtNft_V2_ABI,
        address: ArtNft_V2_ADDRESS,
      });
      const is = await ArtNFT_V2.isApprovedForAll(_address, ConfluxShop_PaiV2);
      console.log(is);
      if (!is) {
        this.$toast.loading({
          message: "contract authorization in progress...",
          duration: 0,
          overlay: true,
          forbidClick: true,
        });
        const collateral1 = await ArtNFT_V2.setApprovalForAll(
          ConfluxShop_PaiV2,
          true
        ).estimateGasAndCollateral({ from: _address });
        await ArtNFT_V2.setApprovalForAll(ConfluxShop_PaiV2, true)
          .sendTransaction({
            from: _address,
            gas: collateral1.gasLimit,
            storageLimit: collateral1.storageCollateralized,
          })
          .executed();

        this.$toast.clear();
      }

      const price2 = +this.price2;
      const price3 = +this.price3;
      const endtime = +this.endtime;

      this.show2 = true;
      this.show = false;

      try {
        const SHOPPAI_V2 = await this.conflux.Contract({
          abi: ConfluxShop_PaiV2_ABI,
          address: ConfluxShop_PaiV2,
        });

        // 挂出拍卖
        let collateral = await SHOPPAI_V2.sellTicker(
          +this.tid, // tokenid
          +this.$drip.fromCFX(price2),
          +this.$drip.fromCFX(price3),
          +endtime,
          +this.amount2, // amount
          ArtNft_V2_ADDRESS // NFT_address
        ).estimateGasAndCollateral({ from: _address });

        const _shoppai = await SHOPPAI_V2.sellTicker(
          +this.tid, // tokenid
          +this.$drip.fromCFX(price2),
          +this.$drip.fromCFX(price3),
          +endtime,
          +this.amount2, // amount
          ArtNft_V2_ADDRESS // NFT_address
        )
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();

        // 设置拍卖中
        let time2 = Math.floor(Date.now() / 1000);
        time2 = time2 + endtime * 3600;
        this.$axios
          .put(this.$store.state.api + "/digital/" + this.id, {
            pai_price: price2, // 起拍价
            pai_end_at: time2, // 拍卖时间 3600秒
            pai_stock: +this.amount2,
            pai_contract_address: ConfluxShop_PaiV2,
          })
          .then((response) => {
            this.init();
          });
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("This NFT is already in Shop") > 0) {
          this.$dialog.alert({
            message: "Your NFT is already in Shop",
          });
        }
        this.show2 = false;
      }
      this.show2 = false;
    },
    // 下架拍卖 或 确认成交
    async cancelauction(tid, bidnum, id) {
      const _address = await window.getAddress();
      this.show2 = true;
      this.show = false;
      const SHOPPAI_V2 = await this.conflux.Contract({
        abi: ConfluxShop_PaiV2_ABI,
        address: ConfluxShop_PaiV2,
      });

      const pai = await SHOPPAI_V2.shopMapNew(ArtNft_V2_ADDRESS, tid);
      const seller = pai[0]; //卖出者
      const buyer = pai[2]; //买入者
      const amount = pai[6];

      try {
        // 下架
        if (+bidnum < 1) {
          let collateral = await SHOPPAI_V2.cancelShell(
            +tid, //NFT ID号
            amount, //NFT 数量
            ArtNft_V2_ADDRESS //NFT 的合约地址
          ).estimateGasAndCollateral({
            from: _address,
          });
          console.log(collateral);
          const pai = await SHOPPAI_V2.cancelShell(
            +tid, //NFT ID号
            amount, //NFT 数量
            ArtNft_V2_ADDRESS //NFT 的合约地址
          )
            .sendTransaction({
              gas: collateral.gasLimit,
              storageLimit: collateral.storageCollateralized,
              from: _address,
            })
            .executed();
        } else {
          // 确认交易完成
          let collateral = await SHOPPAI_V2.Bid_or_retrieve_NFT(
            +tid, //NFT ID号
            amount, //NFT 数量
            ArtNft_V2_ADDRESS //NFT 的合约地址
          ).estimateGasAndCollateral({
            from: _address,
            value: 0,
          });
          const pai = await SHOPPAI_V2.Bid_or_retrieve_NFT(
            +tid, //NFT ID号
            amount, //NFT 数量
            ArtNft_V2_ADDRESS //NFT 的合约地址
          )
            .sendTransaction({
              gas: collateral.gasLimit,
              storageLimit: collateral.storageCollateralized,
              value: 0,
              from: _address,
            })
            .executed();
        }
      } catch (error) {
        console.log(error);
      }
      this.show2 = false;

      // 设置交易完成
      this.$axios
        .put(this.$store.state.api + "/digital/" + id, {
          address: buyer,
          pai_address: "",
          pai_price: 0,
        })
        .then((response) => {
          this.showpai = false;
          this.init();
        });
    },
    // 取回拍卖
    async getauction(tid, id) {
      // 是否到期

      this.show2 = true;
      this.show = false;

      const _address = await window.getAddress();

      const SHOPPAI_V2 = await this.conflux.Contract({
        abi: ConfluxShop_PaiV2_ABI,
        address: ConfluxShop_PaiV2,
      });

      const pai = await SHOPPAI_V2.shopMapNew(ArtNft_V2_ADDRESS, tid);
      const seller = pai[0]; //卖出者
      const buyer = pai[2]; //买入者
      const amount = pai[6];

      let collateral = await SHOPPAI_V2.Bid_or_retrieve_NFT(
        +tid, //NFT ID号
        amount, //NFT 数量
        ArtNft_V2_ADDRESS //NFT 的合约地址
      ).estimateGasAndCollateral({
        from: _address,
        value: 0,
      });
      await SHOPPAI_V2.Bid_or_retrieve_NFT(
        +tid, //NFT ID号
        amount, //NFT 数量
        ArtNft_V2_ADDRESS //NFT 的合约地址
      )
        .sendTransaction({
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
          value: 0,
          from: _address,
        })
        .executed();

      // 设置交易完成
      this.$axios
        .put(this.$store.state.api + "/digital/" + id, {
          address: buyer,
          pai_address: "",
          pai_price: 0,
        })
        .then((response) => {
          this.showpai = false;
          this.show2 = false;
          this.init();
        });
    },

    // 销毁作品
    async burnit() {
      this.$dialog
        .confirm({
          title: "Do you need to burn this NFT? ",
          message: "This operation is irreversible, please operate with caution!",
          confirmButtonText: "Yes",
          confirmButtonColor: "#000",
          cancelButtonText: "No",
          cancelButtonColor: "red",
        })
        .then(async () => {
          // on confirm

          try {
            const _address = await window.getAddress();

            const ArtNft_V2 = await conflux.Contract({
              abi: ArtNft_V2_ABI,
              address: ArtNft_V2_ADDRESS,
            });

            this.$toast.loading({
              message: "处理中...",
              duration: 0,
            });

            //const amount = await ArtNft_V2.balanceOf(_address, +this.tid); // 铸造中的 amount

            this.show = false;

            let collateral = await ArtNft_V2.burn(
              _address,
              +this.tid,
              +this.amount
            ).estimateGasAndCollateral({ value: 0, from: _address });
            //console.log(collateral);

            const burn = await ArtNft_V2.burn(_address, +this.tid, +this.amount)
              .sendTransaction({
                gas: collateral.gasLimit,
                storageLimit: collateral.storageCollateralized,
                from: _address,
              })
              .executed();

            this.$toast.clear();

            this.init();
          } catch (error) {
            console.log(error);
            //console.log(error.message.indexOf("Not owner")); // 已经属于别人
            //this.$toast.fail("已经拍卖成功，等待用户取回");
            this.$toast.clear();
            this.show = false;
          }
          this.show = false;
        })
        .catch(() => {
          // on cancel
        });
    },

    // 取消售卖
    async cancelit(tokenid, id) {
      const _address = await window.getAddress();

      try {
        const SHOP_V2 = await this.conflux.Contract({
          abi: ConfluxShop_V2_ABI,
          address: ConfluxShop_V2,
        });

        const shopitem = await SHOP_V2.shopMapNew(ArtNft_V2_ADDRESS, +tokenid); // 售卖中的信息
        const amount = shopitem[3];

        const collateral = await SHOP_V2.cancelShell(
          +tokenid,
          +amount,
          ArtNft_V2_ADDRESS
        ).estimateGasAndCollateral({
          from: _address,
        });

        this.showcancel = false;
        this.show2 = true;

        const cancelshop = await SHOP_V2.cancelShell(+tokenid, +amount, ArtNft_V2_ADDRESS)
          .sendTransaction({
            from: _address,
            gas: collateral.gasLimit,
            storageLimit: collateral.storageCollateralized,
          })
          .executed();

        this.showcancel = false;
        this.show2 = true;

        // 设置取消
        this.$axios
          .put(this.$store.state.api + "/digital/" + id, {
            address: _address,
            price: 0,
          })
          .then(async (response) => {
            this.init();
          }); /* */
      } catch (error) {
        console.log(error);
        this.show2 = false;
      }
      this.show2 = false;
    },

    // 自动生成头像
    async onSubmit() {
      const _address = await this.getAddress();
      this.$axios.defaults.headers.common["A-ADDRESS"] = _address; // AJAX 配置 Authorization
      this.$axios
        .put(this.$store.state.api + "/users/0", {
          name: this.username,
        })
        .then(async (response) => {
          this.myhead =
            this.$store.getters.nft_url_t.replace("temp_pic_pro", "temp_pic_v1") +
            "111/head/" +
            response.data.pic;
          this.userinfo.name = this.username;
          this.showaccount = false;
        });
    },

    // 调起铸造
    async makeitpre() {
      this.zhuzaoshow = true;
    },
    // OLD铸造
    async makeit() {
      if (!this.zhuzaotitle) {
        alert("Please enter a title");
        return;
      }

      if (!this.zhuzaoprice) {
        alert("Please enter price");
        return;
      }

      const _address = await window.getAddress();

      // 链接数据
      this.$axios.defaults.headers.common.Authorization = localStorage.getItem(
        "_address"
      );

      let timeuid = "";
      if (!localStorage.getItem("_timeuid")) {
        timeuid = new Date().getTime();
        localStorage.setItem("_timeuid", timeuid);
      } else {
        timeuid = localStorage.getItem("_timeuid");
      }

      /* 查找对应用户*/
      this.$axios
        .get(this.$store.state.api + "/account?tuid=" + timeuid)
        .then((response) => {
          localStorage.setItem("nft_acc_id", response.data);
          // 保存作品 uri
          if (this.goodsT.pic) {
            this.$axios
              .post(
                this.$store.state.api + "/nft",
                this.$qs.stringify({
                  tuid: timeuid,
                  uid: response.data,
                  title: this.zhuzaotitle,
                  price: this.zhuzaoprice,
                  pic: this.goodsT.pic, // 智能生成的图片 地址
                })
              )
              .then(async (response) => {
                const tt = response.data;

                const _address = conflux.selectedAddress;

                this.show2 = true;
                this.zhuzaoshow = false;

                try {
                  const _artNFT = await window.ArtNFT.mint(tt._id)
                    .sendTransaction({
                      value: +this.$drip.fromCFX(+tt.price),
                      from: _address,
                    })
                    .executed();
                  //.confirmed();

                  console.log(_artNFT);
                  // 设置已经铸造
                  /**/

                  const tokens = await window.ArtNFT.tokensOf(_address);
                  const tokenid = tokens.pop();

                  this.$axios
                    .put(
                      this.$store.state.api + "/nft",
                      this.$qs.stringify({
                        id: tt._id,
                        tokenid: +tokenid[0],
                      })
                    )
                    .then((response) => {
                      this.show2 = false;
                      this.$router.push({ path: "/my" });
                    });
                } catch (error) {
                  this.show2 = false;
                  this.zhuzaoshow = false;
                }
              });
          }
        });
    },
    closes1() {
      this.show = false;
    },
    closes() {
      this.zhuzaoshow = false;
    },

    //---------------------------------------- V2合约
    // 展示大图
    preview(url) {
      this.images = [url];
      this.$viewer.show();
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    // 转到新合约 Transfer to new contrac
    async cast(item) {
      // 获取旧NFT 存入新数据库 https://www.artii.top/api/v1/getnftbyid?id=614addd2abc04acfacf00e71
      const _address = await window.getAddress();

      //this.destroyOldNFT(item.tokenid);

      item.contract = ArtNft_V2_ADDRESS;
      item.address = _address;

      // 移动到新数据库 新图片路径
      this.$axios
        .put(this.$store.state.api + "/transfer/" + item._id, item)
        .then(async (response) => {
          //this.init();
          const data = response.data;
          try {
            const ArtNFT = await conflux.Contract({
              abi: ArtNFT_ABI,
              address: ArtNFT_ADDRESS,
            });
            const listuri = await ArtNFT.uri(+data.tokenid);
            const old_id = listuri.replace("nft.", "");

            // 销毁老合约 换回钱 判断是否是烧毁
            if (this.oldTokenIds.split(",").indexOf("" + data.tokenid) != -1) {
              this.$toast.loading({
                message: "The first step is in progress...",
                duration: 0,
                overlay: true,
                forbidClick: true,
              });
              const burn = await ArtNFT.burn(_address, +data.tokenid)
                .sendTransaction({ from: _address })
                .executed();
            }

            setTimeout(async () => {
              /*const ArtNFT = await conflux.Contract({
                abi: ArtNFT_ABI,
                address: ArtNFT_ADDRESS,
              });
              const listuri = await ArtNFT.uri(+data.tokenid);
              const old_id = listuri.replace("nft.", "");*/

              const listArtNFT = await ArtNFT.tokensOf(_address);
              let t_ids = "";
              listArtNFT.forEach(async (element) => {
                t_ids += element + ",";
              });
              console.log("new ids");
              //console.log(t_ids.split(","));
              console.log(t_ids.split(",").indexOf("" + data.tokenid) === -1);

              if (t_ids.split(",").indexOf("" + data.tokenid) === -1) {
                this.$toast.clear();

                // 老合约中的价格
                const oldPrice = await ArtNFT.userMintPrices(+data.tokenid);
                const price = Drip(oldPrice).toCFX();

                const ArtNft_V2 = await conflux.Contract({
                  abi: ArtNft_V2_ABI,
                  address: ArtNft_V2_ADDRESS,
                });
                console.log("old meta:" + old_id);
                console.log("hash:" + data.hash);
                console.log("token:" + data.tokenid);

                this.$toast.loading({
                  message: "The second step is in progress...",
                  duration: 0,
                  overlay: true,
                  forbidClick: true,
                });

                const collateral = await ArtNft_V2.mint_old(
                  old_id, //旧meta
                  data.hash, //新meta
                  data.tokenid
                ).estimateGasAndCollateral({
                  value: +this.$drip.fromCFX(+price),
                  from: _address,
                });

                await ArtNft_V2.mint_old(
                  old_id, //旧meta
                  data.hash, //新meta
                  data.tokenid
                )
                  .sendTransaction({
                    value: +this.$drip.fromCFX(+price),
                    from: _address,
                    gas: collateral.gasLimit,
                    storageLimit: collateral.storageCollateralized,
                  })
                  .executed();

                // 设置v1 已经转移
                this.$axios
                  .put(this.$store.state.api1 + "/v2/nft/transfer", {
                    id: item.tokenid,
                    uid: item.uid,
                    author: item.author,
                  })
                  .then(async (response) => {
                    this.$toast.clear();
                  });

                this.$toast.clear();
              }
              this.$toast.clear();
              this.init();
            }, 4000);
          } catch (error) {
            this.$toast.clear();
            console.log(error);
            if (error.message.indexOf("Old_NFT_ID_minted") > 0) {
              this.$dialog.alert({
                message: "Old_NFT_ID_minted",
              });
            }
            if (error.message.indexOf("Not V1 NFT Owner") > 0) {
              this.$dialog.alert({
                message: "Not V1 NFT Owner",
              });
            }
          }
        });
    },
    async onStake() {},
    //----------------------------------------------------------
    // 老合约 关闭下架 窗口
    closesOldGoodsOff() {},
    // 老合约 销毁后获取钱 第一步
    async destroyOldNFT(id) {
      const _address = window._address;

      const ArtNFT = await conflux.Contract({
        abi: ArtNFT_ABI,
        address: ArtNFT_ADDRESS,
      });

      const burn = await ArtNFT.burn(_address, +id)
        .sendTransaction({ from: _address })
        .executed();
      setTimeout(() => {
        this.init();
      }, 1000);
    },
    // 老合约 取消售卖 下架
    async offShopping(id) {
      const _address = window._address;

      // 授权
      const ArtNFT = await conflux.Contract({
        abi: ArtNFT_ABI,
        address: ArtNFT_ADDRESS,
      });
      const collateral1 = await ArtNFT.setApprovalForAll(
        SHOP_ADDRESS,
        true
      ).estimateGasAndCollateral({ from: _address });
      const isApproved = await ArtNFT.setApprovalForAll(SHOP_ADDRESS, true)
        .sendTransaction({
          from: _address,
          gas: collateral1.gasLimit,
          storageLimit: collateral1.storageCollateralized,
        })
        .executed();
      console.log(isApproved);
      // 授权END

      const SHOP = await conflux.Contract({
        abi: SHOP_ABI,
        address: SHOP_ADDRESS,
      });

      const shopids = await SHOP.getSellerShop(_address);
      console.log(shopids);

      const collateral = await SHOP.cancelShell(+id).estimateGasAndCollateral({
        from: _address,
      });
      console.log(collateral);
      return;
      const cancelshop = await SHOP.cancelShell(id)
        .sendTransaction({
          from: _address,
          gas: collateral.gasLimit,
          storageLimit: collateral.storageCollateralized,
        })
        .executed();
      console.log(cancelshop);
    },
  },
};
